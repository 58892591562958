import React, {useEffect} from "react";
import mainImage from "../../assets/main-image.png"
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import texts from "../../assets/languages";
import {clearQuestionState} from "../QuestionsPage/questionActions";
import {getActiveLanguage} from "../redux/getters";

const MainPage = () => {
    const dispatch = useDispatch()
    const language = useSelector(getActiveLanguage)
    useEffect(() => {
        dispatch(clearQuestionState())
    }, [dispatch])
    return (
        <div className={"container"}>
            <p className={"text-light-weight text-size"}>
                {texts[language].main.welcomeTitle}
            </p>
            <p className={"text-light-weight text-size"}>
                {texts[language].main.welcomeText}
            </p>
            <div>
                <img className={"w-100"} src={mainImage} alt="4people"/>
            </div>
            <p className={"text-light-weight text-size mb-5"}>
                {texts[language].main.anketaInfo}
            </p>
            <div>
                <Link to={"/questions"}
                      className={"btn border-dark text-light btn-lg bg-dark-green"}>{texts[language].main.inAnketBtn}</Link>
            </div>
            <p className={"text-light-weight text-size mt-5"}>
                {texts[language].main.info1} <a
                href={texts[language].main.infoLinkPath} rel={"noreferrer"} target={"_blank"}>{texts[language].main.infoLink}</a>{texts[language].main.info2}
            </p>
        </div>
    );
};

export default MainPage;
